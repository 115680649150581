import * as React from "react"

// markup
const NotFoundPage = () => {

 

  return (
    <div>
      oops !  
      <h2>This is a 404</h2>
    </div>
  )
}

export default NotFoundPage
